import { CarriageTypesEnum, SvgIconsEnum } from '../enums';

export const CARRIAGE_ICON: {[key: number]: SvgIconsEnum} = {
  [CarriageTypesEnum.OPEN]: SvgIconsEnum.WagonOpen,
  [CarriageTypesEnum.FLAT]: SvgIconsEnum.WagonFlat,
  [CarriageTypesEnum.COVERED]: SvgIconsEnum.WagonCovered,
  [CarriageTypesEnum.HOPPER]: SvgIconsEnum.WagonHopper,
  [CarriageTypesEnum.TANK]: SvgIconsEnum.WagonTank,
  [CarriageTypesEnum.OTHER]: SvgIconsEnum.WagonOther,
}
