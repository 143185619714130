import { Route } from '@angular/router';
import { RoutesHelperEnum, UserRolesEnum } from '@shared/enums';
import { IsAuthorizedGuard, UnauthorizedGuard, UserRolesGuard } from '@shared/guards';

export const appRoutes: Route[] = [
  {
    path: '',
    loadComponent: () => import('@shared/components').then(m => m.LayoutMainComponent),
    children: [
      {
        path: RoutesHelperEnum.AUTH_PATH,
        loadChildren: () => import('@auth').then(m => m.AuthModule),
        canActivate: [UnauthorizedGuard]
      },
      {
        path: RoutesHelperEnum.EMPTY_PATH,
        loadChildren: () => import('@orders').then(m => m.OrdersModule),
        canActivate: [IsAuthorizedGuard]
      },
      {
        path: RoutesHelperEnum.ADMIN_PATH,
        loadChildren: () => import('@admin').then(m => m.AdminModule),
        canActivate: [IsAuthorizedGuard, UserRolesGuard],
        data: {
          roles: [UserRolesEnum.ADMIN],
        }
      },
      {
        path: RoutesHelperEnum.REDIRECT_PATH,
        redirectTo: RoutesHelperEnum.EMPTY_PATH,
      },
    ]
  },
];
