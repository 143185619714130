import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SvgIconsEnum } from '../enums';

@Injectable({
  providedIn: 'root'
})
export class IconsService {
  static ASSETS_IMAGES_PATH = '/assets/svg';
  static svgIcons = [
    SvgIconsEnum.AlertError,
    SvgIconsEnum.ArrowRight,
    SvgIconsEnum.Avatar,
    SvgIconsEnum.AvatarOperator,
    SvgIconsEnum.Calendar,
    SvgIconsEnum.Cancel,
    SvgIconsEnum.Check,
    SvgIconsEnum.Chevron,
    SvgIconsEnum.Download,
    SvgIconsEnum.EyeClose,
    SvgIconsEnum.EyeOpen,
    SvgIconsEnum.FileExport,
    SvgIconsEnum.Hangar,
    SvgIconsEnum.Help,
    SvgIconsEnum.Login,
    SvgIconsEnum.LogoImg,
    SvgIconsEnum.LogoText,
    SvgIconsEnum.Ok,
    SvgIconsEnum.Plus,
    SvgIconsEnum.Reload,
    SvgIconsEnum.RouteLine,
    SvgIconsEnum.SortDown,
    SvgIconsEnum.Train,
    SvgIconsEnum.WagonOpen,
    SvgIconsEnum.WagonCovered,
    SvgIconsEnum.WagonHopper,
    SvgIconsEnum.WagonFlat,
    SvgIconsEnum.WagonTank,
    SvgIconsEnum.WagonOther,
    SvgIconsEnum.ChevronLeft,
    SvgIconsEnum.Copy
  ];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) { }

  registerSvgIcons(): void {
    IconsService.svgIcons.forEach(this.addSvgIcon);
  }

  private addSvgIcon = (imageName: string): void => {
    this.matIconRegistry.addSvgIcon(
      imageName,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${IconsService.ASSETS_IMAGES_PATH}/${imageName}.svg`)
    );
  }
}
