import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiProvider } from '@shared/services';
import { Observable, map } from 'rxjs';
import { IOperatorDictionary } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class OperatorGroupsApiService {

  constructor(
    private apiProvider: ApiProvider,
    private http: HttpClient,
  ) {}

  public getOperators(): Observable<IOperatorDictionary[]> {
    return this.http.get<{items: IOperatorDictionary[]}>(`${this.apiProvider.api}/operator-groups`)
      .pipe(map(data => data.items));
  }
}
