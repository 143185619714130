import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, UrlTree } from '@angular/router';
import { map, Observable, tap } from 'rxjs';
import { UserService } from '../services';
import { IUser } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class UnauthorizedGuard implements CanActivate {
  constructor(
    private readonly userService: UserService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ): | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.userService.user$.pipe(
      map((user: IUser | null) => !user),
      tap((isUnauthorized: boolean) => {
        if (!isUnauthorized) {
          this.userService.navigateByRoles();
        }
      }),
    );
  }
}
