import { HttpClientModule } from '@angular/common/http';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IconsService, UserService } from '@shared/services';

@Component({
  standalone: true,
  imports: [RouterModule, HttpClientModule],
  selector: 'rail-logic-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'rail-logic';

  constructor(
    private iconsService: IconsService,
    private userService: UserService
  ) {
    iconsService.registerSvgIcons();
    userService.getDataFromStorage();
  }
}
