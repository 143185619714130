import { Injectable } from '@angular/core';
import { BehaviorSubject, take } from 'rxjs';
import { Router } from '@angular/router';
import { UserApiService } from './user-api.service';
import { LocalStorageService } from '../local-storage.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LocalStorageKeysEnum, RoutesHelperEnum, UserRolesEnum } from '../../enums';
import { IUser } from '../../interfaces';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly tokenSource = new BehaviorSubject<string | null>(null);
  readonly token$ = this.tokenSource.asObservable();

  private readonly userSource = new BehaviorSubject<IUser | null>(null);
  readonly user$ = this.userSource.asObservable();

  private readonly userCheckSource = new BehaviorSubject<boolean>(false);
  readonly userCheck$ = this.userCheckSource.asObservable();

  private readonly userManagerSource = new BehaviorSubject<boolean | null>(null);
  readonly userManager$ = this.userManagerSource.asObservable();

  private readonly userRoleSource = new BehaviorSubject<UserRolesEnum | null>(null);
  readonly userRole$ = this.userRoleSource.asObservable();

  get token(): string | null {
    return this.tokenSource.value;
  }

  get user(): IUser | null {
    return this.userSource.value;
  }

  get userManager(): boolean | null {
    return this.userManagerSource.value;
  }

  get userRole(): UserRolesEnum | null {
    return this.userRoleSource.value;
  }

  set token(token: string | null) {
    this.tokenSource.next(token);
    this.localStorage.setItem(LocalStorageKeysEnum.Token, token);
  }

  set user(user: any | null) {
    this.userSource.next(user);
    this.localStorage.setItem(LocalStorageKeysEnum.User, user);
    this.defineUserRolePosition();
  }

  set userManager(val: boolean | null) {
    this.userManagerSource.next(val);
  }

  set userRole(val: UserRolesEnum | null) {
    this.userRoleSource.next(val);
  }

  constructor(
    private router: Router,
    private localStorage: LocalStorageService,
    private userApiService: UserApiService,
  ) {}

  navigateByRoles(): void {
    this.router.navigate([RoutesHelperEnum.ROOT_PATH]).then();
  }

  clearLocalStorage(): void {
    this.token = null;
    this.user = null;
  }

  getUserWithoutNavigation(): void {
    this.userApiService.getUser()
      .pipe(
        take(1),
        untilDestroyed(this)
      )
      .subscribe((user: any) => this.user = user);
  }

  getUserWithNavigation(): void {
    this.userApiService.getUser()
      .pipe(
        take(1),
        untilDestroyed(this)
      ).subscribe(this.handleLoginSuccess, this.handleLoginError);
  }

  getDataFromStorage(): void {
    this.token = this.localStorage.getItem<string>(LocalStorageKeysEnum.Token);
    this.user = this.localStorage.getItem<any>(LocalStorageKeysEnum.User);
    this.userCheckSource.next(true);
  }

  getUser() {
    return this.userApiService.getUser();
  }

  clearUserRolePosition(): void {
    this.userManager = null;
    this.userRole = null;
  }

  defineUserRolePosition(): void {
    if (this.user) {
      const roles: UserRolesEnum[] = this.user.roles.filter((role: UserRolesEnum): boolean => role !== UserRolesEnum.USER);
      this.userManager = roles.includes(UserRolesEnum.LOGISTIC) || roles.includes(UserRolesEnum.ADMIN);
      this.userRole = roles[0];
    }
  }

  private handleLoginSuccess = (user: IUser): void => {
    this.user = user;
    this.navigateByRoles();
  }

  private handleLoginError = (): void => {
    this.navigateByRoles();
  }
}
