import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  checkFileError(err: any) {
    if (err instanceof HttpErrorResponse && err.error instanceof Blob && err.error.type === 'application/json') {
      return new Promise<any>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e: Event): void => {
          try {
            const errMsg = JSON.parse((<any>e.target).result);
            reject(new HttpErrorResponse({
              error: errMsg,
              headers: err.headers,
              status: err.status,
              statusText: err.statusText,
              url: err.url
            }));
          } catch (e) {
            reject(err);
          }
        };
        reader.onerror = (): void => {
          reject(err);
        };
        reader.readAsText(err.error);
      });
    }
    return throwError(err);
  }
}