import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http' 
import { Observable } from 'rxjs';
import { ApiProvider } from '../api.provider';

@Injectable({
  providedIn: 'root'
})
export class UserApiService {
  constructor(
    private apiProvider: ApiProvider,
    private http: HttpClient
  ) { }

  public getUser(): Observable<any> {
    return this.http.get(`${this.apiProvider.api}/auth/me`)
  }
}
