import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { combineLatest, filter, map, Observable, tap } from 'rxjs';
import { UserService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class IsAuthorizedGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly userService: UserService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ): | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return combineLatest([this.userService.user$, this.userService.userCheck$]).pipe(
      filter(([, isChecked]) => isChecked),
      map(([user, ]) => !!user),
      tap((isAuthorized: boolean) => {
        if ((!isAuthorized)) {
          this.router.navigate(['/auth','login']).then();
          return false;
        }
        return true;
      }),
    );
  }
}
