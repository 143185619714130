import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserService } from '../services';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private readonly userService: UserService,
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const accessToken = this.userService.token;
    if (accessToken && !request.headers.has('Authorization')) {
      const clonedRequest = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + accessToken) });

      return next.handle(clonedRequest);
    }

    return next.handle(request);
  }
}
