import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, throwError } from 'rxjs';
import { UserService } from '../services';
import { RoutesHelperEnum } from '../enums';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private userService: UserService,
    private router: Router
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError(error => this.handleError(error)));
  }

  private handleError(error: HttpErrorResponse): Observable<any> {
    if (error.status === 401) {
      this.userService.clearLocalStorage();
      this.manageUserNavigation();
    }

    return throwError(() => error);
  }

  private manageUserNavigation(): void {
    if (isDevMode()) {
      this.router.navigate([RoutesHelperEnum.ROOT_PATH, 'auth','login']).then();

      return;
    }
    this.router.navigate(['/auth','login']).then();
  }
}
