import { ApplicationConfig, LOCALE_ID } from '@angular/core';
import { provideRouter } from '@angular/router';
import { appRoutes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_CONFIG } from '@shared/constants';
import { environment } from '../environments/environment';
import { AuthInterceptor, ErrorInterceptor } from '@shared/interceptors';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { ru } from 'date-fns/locale';
import { provideEnvironmentNgxMask } from 'ngx-mask';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(appRoutes),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideEnvironmentNgxMask(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: APP_CONFIG,
      useValue: environment,
    },
    {
      provide: LOCALE_ID,
      useValue: 'ru',
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: ru,
    },
  ],
};
