import { Injectable, isDevMode } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ErrorCodeEnum } from '../enums';

@Injectable({
  providedIn: 'root'
})
export class ValidationErrorService {
  public getValidationErrorMessage(control: FormControl, ignoreDisabledState?: boolean): string | null {
    if (ignoreDisabledState) {
      return 'Обязательное поле';
    }

    if (!control.errors) {
      return null;
    }

    if (control.hasError(ErrorCodeEnum.required)) {
      return 'Обязательное поле';
    }

    if (control.hasError(ErrorCodeEnum.matDatepickerParse)) {
      return 'Формат должен быть ДД/ММ/ГГГГ';
    }

    if (control.hasError(ErrorCodeEnum.email)) {
      return 'Неверный формат почты';
    }

    if (control.hasError(ErrorCodeEnum.minlength)) {
      const requiredLength = control.errors['minlength'].requiredLength;
      return `Минимальное кол-во символов ${requiredLength}`;
    }

    if (control.hasError(ErrorCodeEnum.min)) {
      const min = control.errors['min'].min;
      return `Минимальное значение ${min}`;
    }

    if (control.hasError(ErrorCodeEnum.max)) {
      const min = control.errors['max'].max;
      return `Максимальное значение ${min}`;
    }

    if (control.hasError(ErrorCodeEnum.minBet)) {
      const minBet = control.errors['minBet'].minBet;
      return `мин. шаг ${minBet}`;
    }

    if (control.hasError(ErrorCodeEnum.requiredDateSubmission)) {
      return `Укажите дату подачи вагонов`;
    }

    if (control.hasError(ErrorCodeEnum.makeBetError)) {
      const text = control.errors['makeBetError'].message;
      return `${text}`;
    }

    if (control.hasError(ErrorCodeEnum.wrongPassword)) {
      return 'Введен неверный пароль';
    }

    if (control.hasError(ErrorCodeEnum.wrongEmail)) {
      return 'Логин не найден';
    }

    if (control.hasError(ErrorCodeEnum.invalidCredentials)) {
      return 'Неверный логин или пароль';
    }

    if (control.hasError(ErrorCodeEnum.accessDenied)) {
      return 'Доступ запрещен';
    }

    if (control.hasError(ErrorCodeEnum.serverError)) {
      return String(control.errors[ErrorCodeEnum.serverError]);
    }

    if (control.hasError(ErrorCodeEnum.noWhitespace)) {
      return 'Значение не может состоять из пробелов';
    }

    if (isDevMode()) {
      console.warn('Неизвестная валидационная ошибка', control.errors);
    }

    return null;
  }
}
