import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs'
import { UserService } from '../services';
import { RoutesHelperEnum, UserRolesEnum } from '../enums';

@Injectable({
  providedIn: 'root'
})
export class UserRolesGuard implements CanActivate {
  constructor(
    private router: Router,
    private readonly userService: UserService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ): | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const roles: UserRolesEnum[] = route.data['roles'] as UserRolesEnum[];
    if (!roles) {
      return true;
    }

    return roles.includes(this.userService.userRole)
      ? true
      : this.router.navigate([RoutesHelperEnum.ROOT_PATH]).then();
  }
}
