
export enum RoutesHelperEnum {
  EMPTY_PATH = '',
  ROOT_PATH = '/',
  REDIRECT_PATH = '**',
  AUTH_PATH = 'auth',
  LOGIN_PATH = 'auth/login',
  RESTORE_PATH = 'auth/restore',
  RESET_PASSWORD_PATH = 'reset-password',
  ADMIN_PATH = 'admin',
}
