import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ApiProvider } from './api.provider';
import { IListEntity } from '../interfaces';
import { IDictionaryItem } from '../interfaces';
import { DictionaryItemTypeEnum } from '../enums';

@Injectable({
  providedIn: 'root'
})
export class DictionaryApiService {

  constructor(
    private apiProvider: ApiProvider,
    private http: HttpClient,
  ) {}

  public getCargoList(search: string, page: number, itemsPerPage: number): Observable<IDictionaryItem[]> {
    return this.http.get<IListEntity<IDictionaryItem>>(`${this.apiProvider.api}/cargo?search=${search}&page=${page}&itemsPerPage=${itemsPerPage}`)
            .pipe(map(data => data.items.map(k => ({ ...k, type: DictionaryItemTypeEnum.CARGO }))));
  }

  public getRoadsList(search: string, page: number, itemsPerPage: number): Observable<IDictionaryItem[]> {
    return this.http.get<IListEntity<IDictionaryItem>>(`${this.apiProvider.api}/roads?search=${search}&page=${page}&itemsPerPage=${itemsPerPage}`)
            .pipe(map(data => data.items.map(k => ({ ...k, type: DictionaryItemTypeEnum.ROAD }))));
  }

  public getStationList(search: string, page: number, itemsPerPage: number): Observable<IDictionaryItem[]> {
    return this.http.get<IListEntity<IDictionaryItem>>(`${this.apiProvider.api}/stations?search=${search}&page=${page}&itemsPerPage=${itemsPerPage}`)
            .pipe(map(data => data.items.map(k => ({ ...k, type: DictionaryItemTypeEnum.STATION }))));
  }

  public getCargoById(id: number): Observable<IDictionaryItem> {
    return this.http.get<IDictionaryItem>(`${this.apiProvider.api}/cargo/${id}`)
            .pipe(map(k => ({ ...k, type: DictionaryItemTypeEnum.CARGO })));
  }

  public getStationById(id: number): Observable<IDictionaryItem> {
    return this.http.get<IDictionaryItem>(`${this.apiProvider.api}/stations/${id}`)
            .pipe(map(k => ({ ...k, type: DictionaryItemTypeEnum.STATION })));
  }

  public getRoadById(id: number): Observable<IDictionaryItem> {
    return this.http.get<IDictionaryItem>(`${this.apiProvider.api}/roads/${id}`)
            .pipe(map(k => ({ ...k, type: DictionaryItemTypeEnum.ROAD })));
  }
}
