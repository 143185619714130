
export enum SvgIconsEnum {
  AlertError = 'alert-error',
  ArrowRight = 'arrow-right',
  Avatar = 'avatar-train',
  AvatarOperator = 'avatar-operator',
  Calendar = 'calendar',
  Cancel = 'cancel',
  Check = 'check',
  Chevron = 'chevron',
  Download = 'download',
  EyeClose = 'eye-close',
  EyeOpen = 'eye-open',
  FileExport = 'file-export',
  Hangar = 'hangar',
  Help = 'help',
  Login = 'login',
  LogoImg = 'logo-rl',
  LogoText = 'logo-rail-logic',
  Ok = 'ok',
  Plus = 'plus',
  Reload = 'reload',
  RouteLine = 'route-line',
  SortDown = 'sort-down',
  Train = 'train',
  WagonOpen = 'open-wagon',
  WagonCovered = 'covered-wagon',
  WagonHopper = 'hopper-wagon',
  WagonFlat = 'flat-wagon',
  WagonTank = 'tank-wagon',
  WagonOther = 'other-wagon',
  ChevronLeft = 'chevron-left',
  Copy = 'copy'
}
