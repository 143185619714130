import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE } from '../injection-tokens/local-storage.token';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  constructor(
    @Inject(LOCAL_STORAGE) private readonly localStorage: Storage,
  ) { }

  getItem<T>(key: string): T | null {
    return JSON.parse(this.localStorage.getItem(key) as string);
  }

  setItem(key: string, value: string | number | object | null): void {
    if (value === null) {
      this.removeItem(key);
      return;
    }

    this.localStorage.setItem(key, JSON.stringify(value));
  }

  removeItem(key: string): void {
    this.localStorage.removeItem(key);
  }
}

