import { CarriageTypesEnum } from '../enums';

export const CARRIAGE_LABEL = {
  [CarriageTypesEnum.OPEN]: 'Полувагон',
  [CarriageTypesEnum.FLAT]: 'Платформа',
  [CarriageTypesEnum.COVERED]: 'Крытый',
  [CarriageTypesEnum.HOPPER]: 'Хоппер',
  [CarriageTypesEnum.TANK]: 'Цистерна',
  [CarriageTypesEnum.OTHER]: 'Другие',
}
