import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class ApiProvider {
  public get api() {
    return this.apiUrl
  }

  private readonly apiUrl: string;

  constructor(@Inject(APP_CONFIG) config: any) {
    this.apiUrl = config.apiURL
  }
}
