import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BehaviorSubject, filter, takeUntil, Subject } from "rxjs";

@Injectable({
  providedIn:'root'
})
export class FiltersService {
  private readonly filtersSource = new BehaviorSubject<any | null>(null);
  readonly filters$ = this.filtersSource.asObservable();

  private readonly isFiltersSubscribeSource = new Subject<boolean>();
  readonly isFiltersSubscribe$ = this.isFiltersSubscribeSource.asObservable();

  set isFiltersSubscribe(value: boolean) {
    this.isFiltersSubscribeSource.next(value)
  }

  constructor (private route: ActivatedRoute) {}

  initFilters(filterParamKeys: string[]) {
    this.isFiltersSubscribe = true;
    this.route.queryParams
      .pipe(
        filter(params => !!params),
        takeUntil(this.isFiltersSubscribe$)
      )
      .subscribe(params => {
        const filters: any = {};
        Object.keys(params).forEach(key => {
          if (filterParamKeys.includes(key)) {
            filters[key] = params[key];
          }
        })
        this.filtersSource.next(filters)
      })
  }
}
